var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          ref: "grid",
          attrs: {
            title: "작업 단계",
            columns: _vm.grid.columns,
            data: _vm.grid.data,
            gridHeight: _vm.grid.height,
            editable: _vm.editable && !_vm.param.isRevision,
            hideBottom: true,
            usePaging: false,
            isExcelDown: false,
            filtering: false,
            isFullScreen: true,
            columnSetting: false,
            topBorderClass: "topcolor-orange",
            selection: "multiple",
            rowKey: "mdmSwsStepId",
          },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && Boolean(_vm.param.mdmSwsId)
                    ? _c("c-btn", {
                        attrs: { label: "LBLADD", icon: "add" },
                        on: { btnClicked: _vm.addRow },
                      })
                    : _vm._e(),
                  _vm.editable && Boolean(_vm.param.mdmSwsId)
                    ? _c("c-btn", {
                        attrs: { label: "LBLREMOVE", icon: "remove" },
                        on: { btnClicked: _vm.removeRow },
                      })
                    : _vm._e(),
                  _vm.editable && Boolean(_vm.param.mdmSwsId)
                    ? _c("c-btn", {
                        attrs: { label: "LBLSAVE", icon: "save" },
                        on: { btnClicked: _vm.saveRow },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }