<template>
  <div>
    <c-table
      ref="grid"
      title="작업 단계"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :editable="editable && !param.isRevision"
      :hideBottom="true"
      :usePaging="false"
      :isExcelDown="false"
      :filtering="false"
      :isFullScreen="true"
      :columnSetting="false"
      topBorderClass="topcolor-orange"
      selection="multiple"
      rowKey="mdmSwsStepId"
    >
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLADD" icon="add" @btnClicked="addRow" v-if="editable && Boolean(param.mdmSwsId)" />
          <c-btn label="LBLREMOVE" icon="remove" @btnClicked="removeRow" v-if="editable && Boolean(param.mdmSwsId)" />
          <c-btn label="LBLSAVE" icon="save" @btnClicked="saveRow" v-if="editable && Boolean(param.mdmSwsId)" />
        </q-btn-group>
      </template>
    </c-table>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'work-standard-detail-step',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmSwsId: '',
        plantCd: '',
        updateFlag: '',
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: false,
      grid: {
        columns: [
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: '작업순서',
            required: true,
            style: 'width:100px',
            type: 'number',
            align: 'center',
            sortable: false,
          },
          {
            name: 'mdmSwsStepName',
            field: 'mdmSwsStepName',
            label: '작업단계 명',
            required: true,
            type: 'text',
            align: 'left',
            sortable: false,
          },
          {
            name: 'termTm',
            field: 'termTm',
            label: '예상 소요시간',
            align: 'center',
            type: 'datetime',
            minuteStep: 10,
            style: 'width:200px',
            sortable: false,
          },
          {
            name: 'attach',
            field: 'attach',
            label: '작업단계별 기술문서',
            align: 'center',
            style: 'width:400px',
            type: 'attach',
            taskClassCd: 'SWS_STEP_FILE',
            keyText: 'mdmSwsStepId',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:100px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
        ],
        data: [],
        height: '300px',
      },
      listUrl: '',
      saveUrl: '',
      deleteUrl: '',
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  watch: {
    'param.updateFlag'() {
      this.getList(this.param.updateFlag.split('|')[0])
      if (this.param.updateFlag.split('|')[1] == 'true') {
        this.editable = false;
      } else {
        this.editable = this.$route.meta.editable;
      }
    }
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.listUrl = selectConfig.mdm.sws.step.list.url;
      this.saveUrl = transactionConfig.mdm.sws.step.save.url;
      this.deleteUrl = transactionConfig.mdm.sws.step.delete.url;
      this.getList(this.param.mdmSwsId);
    },
    getList(_mdmSwsId) {
      if (_mdmSwsId) {
        this.$http.url = this.listUrl;
        this.$http.param = {mdmSwsId: _mdmSwsId};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.grid.data = _result.data;
        },);
      }
    },
    saveRow() {
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
        let saveData = this.grid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.saveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                this.getList();
                window.getApp.$emit('APP_REQUEST_SUCCESS');
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: '안내 ', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다., /* 변경된 데이터가 없습니다. */
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    addRow() {
      this.grid.data.push({
        editFlag: 'C',
        mdmSwsId: this.param.mdmSwsId,  // 안전작업 표준 일련번호
        mdmSwsStepId: uid(),  // 안전작업 표준 절차일련번호
        mdmSwsStepName: '',  // 작업절차
        termTm: '',  // 예상 소요시간
        useFlag: 'Y',  // 사용여부
        sortOrder: this.grid.data.length + 1,  // 작업절차 순서
      })
    },
    removeRow() {
      let selectData = this.$refs['grid'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.deleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getList();
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              this.$_.forEach(selectData, item => {
                this.grid.data = this.$_.reject(this.grid.data, item);
              })
              this.$refs['grid'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>